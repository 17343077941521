import { yupResolver } from "@hookform/resolvers/yup";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  Box,
  Button,
  Card,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { addSocialMediaAPI } from "../../redux/resource/addSocialMedia";
import { getResourcesAPI } from "../../redux/resource/getResources";
import Appbar from "../../ui/Appbar";
import Body from "../../ui/Body";
import SideBar from "../../ui/SideBar";
import { addPrivacyPolicyAPI } from "../../redux/resource/addPrivacyPolicy";
import Snack from "../../ui/snack";

const socialMeidaSchema = yup
  .object({
    title: yup.string(),
    desc: yup.string(),
  })
  .required();

export default function PrivacyPolicy() {
  const getResourcesSelector = useSelector((state) => state?.getResources);
  const { result: resourcesData } = getResourcesSelector;
  const addPrivacyPolicySelector = useSelector(
    (state) => state?.addPrivacyPolicy
  );
  const { message } = addPrivacyPolicySelector;
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(socialMeidaSchema) });

  const [snack, setSnack] = useState(false);

  const handleSocialMedia = async (data) => {
    await dispatch(
      addPrivacyPolicyAPI({
        privacyPolicyId: resourcesData?._id,
        privacyPolicy: data,
      })
    );
  };

  useEffect(() => {
    dispatch(getResourcesAPI());
  }, []);

  useEffect(() => {
    let defaultValues = {};
    defaultValues = {
      title: resourcesData?.privacyPolicy?.title,
      desc: resourcesData?.privacyPolicy?.desc,
    };
    reset({ ...defaultValues });
  }, [resourcesData]);

  return (
    <>
      <SideBar />
      <Body>
        <Appbar />
        <Stack spacing={2}>
          <form onSubmit={handleSubmit(handleSocialMedia)}>
            <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
              <Stack direction="row" alignItems="center">
                <Typography>Privacy Policy</Typography>
              </Stack>
            </Paper>
            <Paper sx={{ my: 2, p: 2, borderRadius: 2 }}>
              <Stack spacing={2}>
              <TextField
  multiline
  size="small"
  label="Title"
  {...register("title")}
  sx={{
    lineHeight: 1.8, // Space between lines
    '& .MuiInputBase-input': {
      wordSpacing: '0.2em', // Space between words
      padding: '10px',      // Optional: Padding inside the text field
    }
  }}
/>
<TextField
  multiline
  size="small"
  label="Desc"
  {...register("desc")}
  sx={{
    lineHeight: 1.8,
    '& .MuiInputBase-input': {
      wordSpacing: '0.2em',
      padding: '10px',
    }
  }}
/>

                <Box>
                  <Button type="submit" variant="contained">
                    Add
                  </Button>
                </Box>
              </Stack>
            </Paper>
          </form>
        </Stack>
      </Body>
    </>
  );
}
