import {
  Autocomplete,
  Box,
  Button,
  Card,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { asigneToNGOAPI, asigneToOrgAPI } from "../../redux/slb/asigneToOrg";
import { getSlbsForOrgAPI } from "../../redux/slb/getSlbsForOrg";

import { getAllOrgAPI } from "../../redux/orgnisation/getAllOrg";

function AsignSLB({ open, setOpen, asignData }) {
  const getAllOrgSelector = useSelector((state) => state?.getAllOrg);
  const { result } = getAllOrgSelector;
// debugger;
  const asigneToOrgSelector = useSelector((state) => state?.asigneToOrg);
  const { message } = asigneToOrgSelector;

  const dispatch = useDispatch();

  const [ngoDetails, setNgoDetails] = useState();

  const handleAsignToOrg = async () => {
    await dispatch(asigneToOrgAPI({ CLBIds: asignData, OrgId: ngoDetails }));
    await dispatch(getSlbsForOrgAPI());

    setOpen(false);
  };

  const handleClose = () => setOpen(false);

  useEffect(() => {
    dispatch(getAllOrgAPI());
  }, []);

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box className="modal">
          <Card sx={{ p: 2, width: 600 }}>
            <Stack spacing={2}>
              <Typography variant="h6">Assign to an Organization!</Typography>
              <Autocomplete
                disablePortal
                options={result}
                getOptionLabel={(option) => option.name}
                fullWidth
                onChange={(e, value) => {
                  setNgoDetails(value?._id);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Select Organization" />
                )}
              />

              <Stack
                direction="row"
                spacing={2}
                // justifyContent="space-between"
                // alignItems="center"
              >
                <Button onClick={handleAsignToOrg} variant="contained">
                  Assign
                </Button>
                <Button onClick={handleClose} variant="contained" color="error">
                  Close
                </Button>
                {/* Selected SLBs:<span style={text="bold"}>{asignData.length}</span> */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "250px"
                  }}
                >
                  Selected SLBs:{" "}
                  <span style={{ fontWeight: "bold", marginLeft: "4px" }}>
                    {asignData.length}
                  </span>
                </div>
              </Stack>
              {/* <Button onClick={handleAsignToOrg} variant="contained">
                Assign
              </Button> */}
            </Stack>
          </Card>
        </Box>
      </Modal>
    </>
  );
}

export default AsignSLB;
