import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAdminsPermissionAPI } from "../../redux/config/getAdminsPermission";
import Appbar from "../../ui/Appbar";
import Body from "../../ui/Body";
import SideBar from "../../ui/SideBar";
import AddPermission from "./AddPermission";
import EditPermission from "./EditPermission";
import { getAllPermissionsAPI } from "../../redux/permissions/getAllPermissions";
import { SearchOutlined } from "@mui/icons-material";
import Style from "../../styles/clb.module.scss";

function Permission() {
  const getAllPermissionsSelector = useSelector(
    (state) => state?.getAllPermissions
  );
  const { result, loading } = getAllPermissionsSelector;

  const dispatch = useDispatch();

  const [addPermission, setAddPermission] = useState(false);
  const [editPermission, setEditPermission] = useState(false);
  const [permissionData, setPermissionData] = useState(null);

  const [filters, setFilters] = useState({
    topBid: false,
    topCollectors: false,
    // priceRange: [priceSliderResult?.min, priceSliderResult?.max],
    category: "",
  });


  useEffect(() => {
    if (!editPermission) {
      setPermissionData(null);
    }
  }, [editPermission])

  let columns = [
    {
      field: "name",
      headerName: "Permission Name",
      width: 250,
      headerClassName: "super-app-theme--header",
      sortable: false,
    },
    {
      field: "permission",
      headerName: "Permissions",
      width: 700,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Box>
              {params?.row?.permissions?.map((item, idx) => {
                return (
                  <Chip
                    label={item}
                    sx={{ my: 1, mx: 1 }}
                    key={idx}
                    color="primary"
                    variant="outlined"
                  />
                );
              })}
            </Box>
          </>
        );
      },
    },
    {
      field: "edit_permission",
      headerName: "Edit Permissions",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <>
            <Button
              sx={{ my: 1 }}
              onClick={() => handleEditPermission(params?.row?._id, params?.row?.permissions, params?.row?.name)}
              color="info"
              endIcon={<EditIcon />}
            >
              Edit
            </Button>
          </>
        );
      },
    },
  ];

  const handleDeleteNotification = async (catId) => { };

  const handleAddPermission = () => setAddPermission(true);

  const handleEditPermission = (permId, defaultPerms, name) => {
    setPermissionData({
      permissionId: permId,
      defaultPermissions: defaultPerms,
      defaultName: name
    })
    setEditPermission(true);
  };

  useEffect(() => {
    // alert("abc");
    dispatch(getAdminsPermissionAPI());
    dispatch(getAllPermissionsAPI());
  }, [dispatch]);

  const handleSearch = async (e) => {
    dispatch(
      getAllPermissionsAPI({
        name: e.target.value,
      })
    );
  };

  return (
    <>
      <SideBar />
      <Body>
        <Appbar />
        <Paper elevation={0} sx={{ p: 2, borderRadius: 2, my: 2 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center">
              <Typography variant="p">Permissions</Typography>
            </Stack>
            <Stack marginRight={4} direction="row">
              <input
                onChange={handleSearch}
                className={Style.searchBox}
                placeholder="Search By Name"
                style={{ paddingLeft: "10px" }}
              />
              <button disabled className={Style.searchBtn}>
                {" "}
                <SearchOutlined></SearchOutlined>{" "}
              </button>
            </Stack>
            <Stack direction="row" alignItems="center">
              <Button
                onClick={handleAddPermission}
                variant="contained"
                sx={{ color: "#fff" }}
              >
                Add
              </Button>
            </Stack>
          </Stack>
        </Paper>

        <Paper sx={{ height: "80%", width: "100%" }} elevation={0}>
          <DataGrid
            getRowId={(row) => row._id}
            getRowHeight={() => "auto"}
            rows={result}
            columns={columns}
            pageSizeOptions={[25]}
            disableRowSelectionOnClick
            loading={loading && <CircularProgress />}
            disableColumnMenu
            disableColumnFilter
            disableColumnSelector
          />
        </Paper>
      </Body>
      {addPermission && (
        <AddPermission open={addPermission} setOpen={setAddPermission} />
      )}
      {editPermission && (
        <EditPermission
          open={editPermission}
          setOpen={setEditPermission}
          permissionData={permissionData}
        />
      )}
    </>
  );
}

export default Permission;
