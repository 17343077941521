import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import Axios from "../../config/authAxios"

const initialState = { loading: false, result: [], error: null, message: "" }

export const getAllPermissionsAPI = createAsyncThunk(
  "get-all-permissions",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Axios.get(`/permissions/modules`, { params: data });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const counterSlice = createSlice({
  name: 'get-all-pemissions',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllPermissionsAPI.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getAllPermissionsAPI.fulfilled, (state, action) => {
        state.loading = false
        state.result = action.payload?.result
        state.message = action.payload?.message
      })
      .addCase(getAllPermissionsAPI.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload?.error
        state.message = action.payload?.message
      })
  },
})

const getAllPermissionsReducer = counterSlice.reducer

export default getAllPermissionsReducer

