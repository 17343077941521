import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import Axios from "../../config/authAxios"

const initialState = { loading: false, result: [], error: null, message: "" }


export const uploadTatumFileAPI = createAsyncThunk(
    'upload-tatum-file',
    async ({ fileUrl, ipfs }, { rejectWithValue }) => {
      try {
        const response = await Axios.post(`/file/upload/single?ipfs=${ipfs}`,{
            fileUrl,
            ipfs,
          },
        );
        return await response.data; // Return the IPFS file and other response data
      } catch (error) {
        return rejectWithValue(error.response.data); // Return error if the call fails
      }
    }
  );

export const uploadMultipleCLbsAPI = createAsyncThunk(
    'create-multiple',
    async (data, { rejectWithValue }) => {
        try {
            const response = await Axios.post(`/clbs/create/multiple`, data)
            return await response.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }

    }
)

const counterSlice = createSlice({
    name: 'create-multiple',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(uploadMultipleCLbsAPI.pending, (state, action) => {
                state.loading = true
            })
            .addCase(uploadMultipleCLbsAPI.fulfilled, (state, action) => {
                state.loading = false
                state.result = action.payload?.result
                state.message = action.payload?.message
            })
            .addCase(uploadMultipleCLbsAPI.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload?.error
                state.message = action.payload?.message
            })
    },
})

const uploadMultipleCLbsReducer = counterSlice.reducer

export default uploadMultipleCLbsReducer

