import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginAPI } from "../../redux/auth/login";

import LoginBgImg from "../../assets/png/loginBg.png";
import LogoImg from "../../assets/png/logo.png";
import { IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const Login = () => {
  const loginSelector = useSelector((state) => state?.login);
  const { message, error } = loginSelector;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [states, setStates] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [passwordShow, setPasswordShow] = useState(false);

  const handleChange = (e) => {
    const { currentTarget: input } = e;
    const data = { ...states };
    console.log(input.name);
    data[input.name] = input.value;
    setStates({ ...data });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const loginPayload = await dispatch(
      loginAPI({ email: states?.email, password: states?.password })
    );
    if (!loginPayload?.payload?.error) {
      localStorage.setItem(
        "accessToken",
        loginPayload?.payload?.result?.accessToken
      );
      setTimeout(() => {
        window.location.href = "/";
      }, 500);
    }
  };

  return (
    <>
      <div className="w-full my-5 px-12">
        <img src={LogoImg} className="w-auto sm:h-14 h-10 object-cover " />
      </div>
      <div className="w-full h-full flex">
        <div className="p-24  w-[70%] h-full">
          <form
            className="bg-[#F5F5F5] flex flex-col gap-6 justify-between p-12 h-[60vh] w-full rounded-3xl "
            onSubmit={handleSubmit}
          >
            <div className="flex flex-col gap-6">
              <div>
                <h4 className="heading-text-color text-3xl font-bold">
                  Sign in
                </h4>
              </div>
              <div className="flex flex-col gap-2">
                <label htmlFor="email">Email Address1</label>
                <input
                  className="p-2 rounded-md"
                  type="email"
                  id="email"
                  name="email"
                  onChange={handleChange}
                  value={states.email}
                  placeholder="Email Address"
                  required
                />
              </div>
              <div className="flex flex-col gap-2">
                <label htmlFor="password">Password</label>
                <div className="flex flex-row">
                  <input
                    className="rounded-md w-full p-2"
                    type={!passwordShow ? "password" : "text"}
                    id="password"
                    name="password"
                    onChange={handleChange}
                    value={states.password}
                    placeholder="Password"
                    required
                  />
                  {!passwordShow ? (
                    <IconButton onClick={() => setPasswordShow(true)}>
                      <VisibilityIcon color="primary" />
                    </IconButton>
                  ) : (
                    <IconButton onClick={() => setPasswordShow(false)}>
                      <VisibilityOffIcon color="primary" />
                    </IconButton>
                  )}
                </div>
              </div>
            </div>
            <div>
              <div>
                <button className="w-full primary-btn p-2 rounded-md">
                  Sign in
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="w-full">
          <img src={LoginBgImg} alt="login image" />
        </div>
      </div>
    </>
  );
};

export default Login;
