// // import React, { useEffect, useState } from "react";
// // import {
// //   Button,
// //   CircularProgress,
// //   Modal,
// //   Paper,
// //   Stack,
// //   Typography,
// //   Box,
// //   TextField,
// // } from "@mui/material";
// // import { DataGrid } from "@mui/x-data-grid";
// // import { CSVLink } from "react-csv";
// // import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
// // import Appbar from "../../ui/Appbar";
// // import Body from "../../ui/Body";
// // import SideBar from "../../ui/SideBar";
// // import Axios from "../../config/authAxios";

// // // API Call Function
// // const fetchOrders = async () => {
// //   try {
// //     const response = await Axios.get("/orders-management/get-order-management");
// //     if (response) {
// //       return response.data.result; // Extracting the result array
// //     } else {
// //       console.error("Error fetching orders:", response.data.message);
// //       return [];
// //     }
// //   } catch (error) {
// //     console.error("API call failed:", error);
// //     return [];
// //   }
// // };

// // function Table() {
// //   const [orders, setOrders] = useState([]);
// //   const [loading, setLoading] = useState(true);
// //   const [csvData, setCsvData] = useState([]);
// //   const [openModal, setOpenModal] = useState(false);
// //   const [modalData, setModalData] = useState({}); // Store modal-specific data
// //   const [formData, setFormData] = useState({}); // Store form data for submission

// //   // Fetch Orders on Mount
// //   useEffect(() => {
// //     const getOrders = async () => {
// //       const fetchedOrders = await fetchOrders();
// //       setOrders(fetchedOrders);
// //       setLoading(false);
// //     };
// //     getOrders();
// //   }, []);

// //   // Prepare CSV Data
// //   useEffect(() => {
// //     const csvData = orders.map((order) => ({
// //       OrderID: order._id,
// //       CLBID: order?.clbId?._id,
// //       RoyaltyFeePercentage: order?.clbId?.royaltyFee?.percentage,
// //       BuyerName: order?.buyerId?.name,
// //       BuyerEmail: order?.buyerId?.email,
// //       SellerName: order?.sellerId?.name,
// //       SellerEmail: order?.sellerId?.email,
// //       PriceINR: order?.price?.inr,
// //       PriceUSD: order?.price?.usd,
// //       SellerPaymentStatus: order?.sellerPayment?.status,
// //       CreatorPaymentStatus: order?.creatorPayment?.status,
// //       CreatedAt: new Date(order?.created_at).toLocaleDateString(),
// //     }));
// //     setCsvData(csvData);
// //   }, [orders]);

// //   // Submit form data
// //   const handleSubmit = async () => {
// //     try {
// //       const response = await Axios.post(
// //         "/payment-processing",
// //         formData // Send the form data
// //       );
// //       if (response.data.error === false) {
// //         alert("Payment processed successfully!");
// //         setOpenModal(false);
// //       } else {
// //         alert("Payment processing failed. Please try again.");
// //       }
// //     } catch (error) {
// //       console.error("Error submitting payment details:", error);
// //       alert("An error occurred. Please try again.");
// //     }
// //   };

// //   // Define Modal Content
// //   const renderModalContent = () => {
// //     return (
// //       <Box
// //         sx={{
// //           position: "absolute",
// //           top: "50%",
// //           left: "50%",
// //           transform: "translate(-50%, -50%)",
// //           width: 400,
// //           bgcolor: "background.paper",
// //           boxShadow: 24,
// //           p: 4,
// //           borderRadius: 2,
// //         }}
// //       >
// //         <Typography variant="h6" sx={{ mb: 2 }}>
// //           Payment Details
// //         </Typography>
// //         <TextField
// //           fullWidth
// //           label="Order ID"
// //           value={modalData.orderId || ""}
// //           disabled
// //           sx={{ mb: 2 }}
// //         />
// //         {modalData.type === "Royalty Fee" && (
// //           <TextField
// //             fullWidth
// //             label="Creator Transaction ID"
// //             value={formData.creatorTransId || ""}
// //             onChange={(e) =>
// //               setFormData((prev) => ({
// //                 ...prev,
// //                 creatorTransId: e.target.value,
// //                 orderId: modalData.orderId,
// //                 status: "completed",
// //               }))
// //             }
// //             sx={{ mb: 2 }}
// //           />
// //         )}
// //         {modalData.type === "Seller Fee" && (
// //           <TextField
// //             fullWidth
// //             label="Seller Transaction ID"
// //             value={formData.sellerTransId || ""}
// //             onChange={(e) =>
// //               setFormData((prev) => ({
// //                 ...prev,
// //                 sellerTransId: e.target.value,
// //                 orderId: modalData.orderId,
// //                 status: "completed",
// //               }))
// //             }
// //             sx={{ mb: 2 }}
// //           />
// //         )}
// //         <Button
// //           variant="contained"
// //           sx={{ mt: 2 }}
// //           onClick={handleSubmit}
// //           disabled={!formData.creatorTransId && !formData.sellerTransId} // Disable if no data
// //         >
// //           Submit
// //         </Button>
// //         <Button
// //           variant="outlined"
// //           sx={{ mt: 2, ml: 2 }}
// //           onClick={() => setOpenModal(false)}
// //         >
// //           Close
// //         </Button>
// //       </Box>
// //     );
// //   };

// //   // Define Table Columns
// //   //   const columns = [
// //   //     {
// //   //       field: "buyerName",
// //   //       headerName: "Buyer Name",
// //   //       width: 160,
// //   //       renderCell: (params) => {
// //   //         return <Typography>{params?.row?.buyerId?.name || "N/A"}</Typography>;
// //   //       },
// //   //     },
// //   //     {
// //   //       field: "actions",
// //   //       headerName: "Actions",
// //   //       width: 200,
// //   //       renderCell: (params) => (
// //   //         <Stack direction="row" spacing={1}>
// //   //           <Button
// //   //             variant="contained"
// //   //             onClick={() => {
// //   //               setModalData({
// //   //                 type: "Royalty Fee",
// //   //                 orderId: params.row._id,
// //   //               });
// //   //               setFormData({}); // Clear form data
// //   //               setOpenModal(true);
// //   //             }}
// //   //           >
// //   //             Royalty Fee
// //   //           </Button>
// //   //           <Button
// //   //             variant="contained"
// //   //             onClick={() => {
// //   //               setModalData({
// //   //                 type: "Seller Fee",
// //   //                 orderId: params.row._id,
// //   //               });
// //   //               setFormData({}); // Clear form data
// //   //               setOpenModal(true);
// //   //             }}
// //   //           >
// //   //             Seller Fee
// //   //           </Button>
// //   //         </Stack>
// //   //       ),
// //   //     },
// //   //   ];
// //   const columns = [
// //     {
// //       field: "buyerName",
// //       headerName: "Buyer Name",
// //       width: 160,
// //       renderCell: (params) => {
// //         return <Typography>{params?.row?.buyerId?.name || "N/A"}</Typography>;
// //       },
// //     },
// //     {
// //       field: "buyerEmail",
// //       headerName: "Buyer Email",
// //       width: 200,
// //       renderCell: (params) => {
// //         return <Typography>{params?.row?.buyerId?.email || "N/A"}</Typography>;
// //       },
// //     },
// //     {
// //       field: "sellerName",
// //       headerName: "Seller Name",
// //       width: 160,
// //       renderCell: (params) => {
// //         return <Typography>{params?.row?.sellerId?.name || "N/A"}</Typography>;
// //       },
// //     },
// //     {
// //       field: "sellerEmail",
// //       headerName: "Seller Email",
// //       width: 200,
// //       renderCell: (params) => {
// //         return <Typography>{params?.row?.sellerId?.email || "N/A"}</Typography>;
// //       },
// //     },
// //     {
// //       field: "creatorName",
// //       headerName: "Creator Name",
// //       width: 160,
// //       renderCell: (params) => {
// //         return <Typography>{params?.row?.creatorId?.name || "N/A"}</Typography>;
// //       },
// //     },
// //     {
// //       field: "creatorEmail",
// //       headerName: "Creator Email",
// //       width: 200,
// //       renderCell: (params) => {
// //         return (
// //           <Typography>{params?.row?.creatorId?.email || "N/A"}</Typography>
// //         );
// //       },
// //     },
// //     {
// //       field: "priceINR",
// //       headerName: "Price (INR)",
// //       width: 160,
// //       renderCell: (params) => {
// //         return <Typography>{params?.row?.price?.inr || "N/A"}</Typography>;
// //       },
// //     },
// //     {
// //       field: "priceUSD",
// //       headerName: "Price (USD)",
// //       width: 160,
// //       renderCell: (params) => {
// //         return <Typography>{params?.row?.price?.usd || "N/A"}</Typography>;
// //       },
// //     },
// //     {
// //       field: "sellerPaymentStatus",
// //       headerName: "Seller Payment Status",
// //       width: 200,
// //       renderCell: (params) => {
// //         return (
// //           <Typography>{params?.row?.sellerPayment?.status || "N/A"}</Typography>
// //         );
// //       },
// //     },
// //     {
// //       field: "creatorPaymentStatus",
// //       headerName: "Creator Payment Status",
// //       width: 200,
// //       renderCell: (params) => {
// //         return (
// //           <Typography>
// //             {params?.row?.creatorPayment?.status || "N/A"}
// //           </Typography>
// //         );
// //       },
// //     },
// //     {
// //       field: "actions",
// //       headerName: "Actions",
// //       width: 200,
// //       renderCell: (params) => (
// //         <Stack direction="row" spacing={1}>
// //           <Button
// //             variant="contained"
// //             onClick={() => {
// //               setModalData({
// //                 type: "Royalty Fee",
// //                 orderId: params.row._id,
// //               });
// //               setFormData({}); // Clear form data
// //               setOpenModal(true);
// //             }}
// //           >
// //             Royalty Fee
// //           </Button>
// //           <Button
// //             variant="contained"
// //             onClick={() => {
// //               setModalData({
// //                 type: "Seller Fee",
// //                 orderId: params.row._id,
// //               });
// //               setFormData({}); // Clear form data
// //               setOpenModal(true);
// //             }}
// //           >
// //             Seller Fee
// //           </Button>
// //         </Stack>
// //       ),
// //     },
// //   ];

// //   return (
// //     <>
// //       <SideBar />
// //       <Body>
// //         <Appbar />
// //         <Paper elevation={0} sx={{ p: 2, borderRadius: 2, my: 2 }}>
// //           <Stack direction="row" alignItems="center">
// //             <Stack direction="row" alignItems="center" sx={{ flex: 1 }}>
// //               <Typography variant="p">Orders</Typography>
// //             </Stack>
// //             <CSVLink
// //               data={csvData}
// //               filename={`orders-${new Date().toISOString()}.csv`}
// //             >
// //               <Button variant="outlined">
// //                 <CloudDownloadIcon sx={{ mr: 1 }} />
// //                 Download Data
// //               </Button>
// //             </CSVLink>
// //           </Stack>
// //         </Paper>

// //         <Paper sx={{ height: "80%", width: "100%" }} elevation={0}>
// //           <DataGrid
// //             getRowHeight={() => "auto"}
// //             getRowId={(row) => row._id}
// //             rows={orders}
// //             columns={columns}
// //             pageSizeOptions={[25]}
// //             disableRowSelectionOnClick
// //             loading={loading && <CircularProgress />}
// //             disableColumnMenu
// //           />
// //         </Paper>

// //         <Modal open={openModal} onClose={() => setOpenModal(false)}>
// //           {renderModalContent()}
// //         </Modal>
// //       </Body>
// //     </>
// //   );
// // }

// // export default Table;

// import React, { useEffect, useState } from "react";
// import {
//   Button,
//   CircularProgress,
//   Modal,
//   Paper,
//   Stack,
//   Typography,
//   Box,
//   TextField,
// } from "@mui/material";
// import { DataGrid } from "@mui/x-data-grid";
// import { CSVLink } from "react-csv";
// import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
// import Appbar from "../../ui/Appbar";
// import Body from "../../ui/Body";
// import SideBar from "../../ui/SideBar";
// import Axios from "../../config/authAxios";

// const fetchOrders = async () => {
//   try {
//     const response = await Axios.get("/orders-management/get-order-management");
//     if (response) {
//       return response.data.result;
//     } else {
//       console.error("Error fetching orders:", response.data.message);
//       return [];
//     }
//   } catch (error) {
//     console.error("API call failed:", error);
//     return [];
//   }
// };

// function Table() {
//   const [orders, setOrders] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [csvData, setCsvData] = useState([]);
//   const [openModal, setOpenModal] = useState(false);
//   const [modalData, setModalData] = useState({}); // Modal-specific data
//   const [formData, setFormData] = useState({}); // Form data in modal
//   const [submitting, setSubmitting] = useState(false);

//   // Fetch Orders on Mount
//   useEffect(() => {
//     const getOrders = async () => {
//       const fetchedOrders = await fetchOrders();
//       setOrders(fetchedOrders);
//       setLoading(false);
//     };
//     getOrders();
//   }, []);

//   // Prepare CSV Data
//   useEffect(() => {
//     const csvData = orders.map((order) => ({
//       OrderID: order._id,
//       CLBID: order?.clbId?._id,
//       RoyaltyFeePercentage: order?.clbId?.royaltyFee?.percentage,
//       BuyerName: order?.buyerId?.name,
//       BuyerEmail: order?.buyerId?.email,
//       SellerName: order?.sellerId?.name,
//       SellerEmail: order?.sellerId?.email,
//       PriceINR: order?.price?.inr,
//       PriceUSD: order?.price?.usd,
//       SellerPaymentStatus: order?.sellerPayment?.status,
//       CreatorPaymentStatus: order?.creatorPayment?.status,
//       CreatedAt: new Date(order?.created_at).toLocaleDateString(),
//     }));
//     setCsvData(csvData);
//   }, [orders]);

//   const handleSubmit = async () => {
//     setSubmitting(true);
//     try {
//       const payload = {
//         orderId: modalData.orderId,
//         status: "completed",
//         ...(modalData.type === "Royalty Fee"
//           ? { creatorTransId: formData.transactionId }
//           : { sellerTransId: formData.transactionId }),
//       };

//       const response = await Axios.post(
//         "/orders-management/update-transaction",
//         payload
//       );
//       if (response && response.data) {
//         alert("Transaction updated successfully");
//         setOpenModal(false);
//       } else {
//         alert("Failed to update transaction");
//       }
//     } catch (error) {
//       console.error("Error submitting transaction:", error);
//       alert("An error occurred while updating the transaction");
//     } finally {
//       setSubmitting(false);
//     }
//   };

//   const renderModalContent = () => {
//     return (
//       <Box
//         sx={{
//           position: "absolute",
//           top: "50%",
//           left: "50%",
//           transform: "translate(-50%, -50%)",
//           width: 400,
//           bgcolor: "background.paper",
//           boxShadow: 24,
//           p: 4,
//           borderRadius: 2,
//         }}
//       >
//         <Typography variant="h6" sx={{ mb: 2 }}>
//           {modalData.type}
//         </Typography>
//         <TextField
//           fullWidth
//           label="Transaction ID"
//           value={formData.transactionId || ""}
//           onChange={(e) =>
//             setFormData({ ...formData, transactionId: e.target.value })
//           }
//           sx={{ mb: 2 }}
//         />
//         <Button
//           variant="contained"
//           fullWidth
//           disabled={!formData.transactionId || submitting}
//           onClick={handleSubmit}
//         >
//           {submitting ? <CircularProgress size={24} /> : "Submit"}
//         </Button>
//         <Button
//           variant="outlined"
//           fullWidth
//           sx={{ mt: 1 }}
//           onClick={() => setOpenModal(false)}
//         >
//           Cancel
//         </Button>
//       </Box>
//     );
//   };
//   const columns = [
//     {
//       field: "buyerName",
//       headerName: "Buyer Name",
//       width: 160,
//       renderCell: (params) => params?.row?.buyerId?.name || "N/A",
//     },
//     {
//       field: "buyerEmail",
//       headerName: "Buyer Email",
//       width: 200,
//       renderCell: (params) => params?.row?.buyerId?.email || "N/A",
//     },
//     {
//       field: "sellerName",
//       headerName: "Seller Name",
//       width: 160,
//       renderCell: (params) => params?.row?.sellerId?.name || "N/A",
//     },
//     {
//       field: "sellerEmail",
//       headerName: "Seller Email",
//       width: 200,
//       renderCell: (params) => params?.row?.sellerId?.email || "N/A",
//     },
//     {
//       field: "priceINR",
//       headerName: "Price (INR)",
//       width: 160,
//       renderCell: (params) => params?.row?.price?.inr || "N/A",
//     },
//     {
//       field: "priceUSD",
//       headerName: "Price (USD)",
//       width: 160,
//       renderCell: (params) => params?.row?.price?.usd || "N/A",
//     },
//     {
//       field: "royaltyFee",
//       headerName: "Royalty Fee (%)",
//       width: 160,
//       renderCell: (params) =>
//         params?.row?.clbId?.royaltyFee?.percentage || "N/A",
//     },
//     {
//       field: "actions",
//       headerName: "Actions",
//       width: 200,
//       renderCell: (params) => (
//         <Stack direction="row" spacing={1}>
//           <Button
//             variant="contained"
//             onClick={() => {
//               setModalData({
//                 type: "Royalty Fee",
//                 ...params.row.creatorPayment,
//                 payout: params.row.buyerId.payout,
//                 bankDetail: params.row.buyerId.bankDetail,
//                 cryptoWallet: params.row.buyerId.cryptoWallet,
//                 transactionId: params.row.sellerPayment.transactionId,
//               });
//               setOpenModal(true);
//             }}
//           >
//             Royalty Fee
//           </Button>
//           <Button
//             variant="contained"
//             onClick={() => {
//               setModalData({
//                 type: "Seller Fee",
//                 ...params.row.sellerPayment,
//                 payout: params.row.buyerId.payout,
//                 bankDetail: params.row.buyerId.bankDetail,
//                 cryptoWallet: params.row.buyerId.cryptoWallet,
//                 transactionId: params.row.sellerPayment.transactionId,
//               });
//               setOpenModal(true);
//             }}
//           >
//             Seller Fee
//           </Button>
//         </Stack>
//       ),
//     },
//   ];

//   return (
//     <>
//       <SideBar />
//       <Body>
//         <Appbar />
//         <Paper elevation={0} sx={{ p: 2, borderRadius: 2, my: 2 }}>
//           <Stack direction="row" alignItems="center">
//             <Stack direction="row" alignItems="center" sx={{ flex: 1 }}>
//               <Typography variant="p">Orders</Typography>
//             </Stack>
//             <CSVLink
//               data={csvData}
//               filename={`orders-${new Date().toISOString()}.csv`}
//             >
//               <Button variant="outlined">
//                 <CloudDownloadIcon sx={{ mr: 1 }} />
//                 Download Data
//               </Button>
//             </CSVLink>
//           </Stack>
//         </Paper>

//         <Paper sx={{ height: "80%", width: "100%" }} elevation={0}>
//           <DataGrid
//             getRowHeight={() => "auto"}
//             getRowId={(row) => row._id}
//             rows={orders}
//             columns={columns}
//             pageSizeOptions={[25]}
//             disableRowSelectionOnClick
//             loading={loading && <CircularProgress />}
//             disableColumnMenu
//           />
//         </Paper>

//         <Modal open={openModal} onClose={() => setOpenModal(false)}>
//           {renderModalContent()}
//         </Modal>
//       </Body>
//     </>
//   );
// }

// export default Table;

import React, { useEffect, useState } from "react";

import { Tooltip } from "@mui/material";
import {
  Button,
  CircularProgress,
  Modal,
  Paper,
  Stack,
  Typography,
  Box,
  TextField,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { CSVLink } from "react-csv";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Appbar from "../../ui/Appbar";
import Body from "../../ui/Body";
import SideBar from "../../ui/SideBar";
import Axios from "../../config/authAxios";

// Normalize Orders Data
const normalizeOrders = (orders) => {
  console.log("nor", orders);
  return orders.map((order) => ({
    orderId: order._id,
    buyer: {
      name: order?.buyerId?.name || "N/A",
      email: order?.buyerId?.email || "N/A",
    },
    seller: {
      name: order?.sellerId?.name || "N/A",
      email: order?.sellerId?.email || "N/A",
      role: order?.sellerId?.role || "N/A",
    },
    price: {
      inr: order?.price?.inr || "N/A",
      usd: order?.price?.usd || "N/A",
    },
    creator: {
      id: order?.creatorId?._id || "N/A",
      name: order?.creatorId?.name || "N/A",
      email: order?.creatorId?.email || "N/A",
    },
    creatorPayment: {
      amountINR: order?.creatorPayment?.amountINR || "N/A",
      amountUSD: order?.creatorPayment?.amountUSD || "N/A",
    },
    sellerBankDetails: {
      accountName: order?.sellerId?.bankDetail?.name,
      accountNumber: order?.sellerId?.bankDetail?.accountNumber,
      accountType: order?.sellerId?.bankDetail?.accountType,
      branchName: order?.sellerId?.bankDetail?.branchName,
      ifscCode: order?.sellerId?.bankDetail?.ifscCode,
      name: order?.sellerId?.bankDetails?.name,
    },
    creatorPayments: {
      amountINR: order?.creatorPayment?.amountINR || "N/A",
      amountUSD: order?.creatorPayment?.amountUSD || "N/A",
    },
    royaltyFee: order?.clbId?.royaltyFee?.percentage || "N/A",
    sellerPayment: order?.sellerPayment || {},

    createdAt: new Date(order?.created_at).toLocaleDateString(),
  }));
};

const fetchOrders = async () => {
  try {
    const response = await Axios.get("/orders-management/get-order-management");
    console.log("ressssssssss", response.data.result);
    if (response) {
      return normalizeOrders(response.data.result);
    } else {
      console.error("Error fetching orders:", response.data.message);
      return [];
    }
  } catch (error) {
    console.error("API call failed:", error);
    return [];
  }
};

function Table() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [csvData, setCsvData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [formData, setFormData] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const getOrders = async () => {
      const fetchedOrders = await fetchOrders();
      setOrders(fetchedOrders);
      setLoading(false);
    };
    getOrders();
  }, []);
  useEffect(() => {
    const lowercasedQuery = searchQuery.toLowerCase();

    const filtered = orders.filter((order) => {
      // Combine all searchable values into a single array
      const searchableValues = [
        order?.buyer?.name,
        order?.buyer?.email,
        order?.seller?.name,
        order?.seller?.email,
        order?.seller?.role,
        order?.creator?.name,
        order?.creator?.email,
        order?.sellerBankDetails?.accountName,
        order?.sellerBankDetails?.accountNumber,
        order?.sellerBankDetails?.accountType,
        order?.sellerBankDetails?.branchName,
        order?.sellerBankDetails?.ifscCode,
        order?.price?.inr,
        order?.price?.usd,
        order?.royaltyFee,
        order?.orderId,
      ];

      // Check if any value matches the search query
      return searchableValues.some((value) =>
        value?.toString().toLowerCase().includes(lowercasedQuery)
      );
    });

    setFilteredOrders(filtered);
  }, [searchQuery, orders]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    console.log("oreders", orders);
    const csvData = orders.map((order) => ({
      OrderID: order.orderId,
      BuyerName: order.buyer.name,
      BuyerEmail: order.buyer.email,
      SellerName: order.seller.name,
      SellerEmail: order.seller.email,
      SellerRole: order.seller.role,
      CreatorDetails: order.creatorId,
      CreatorBank: order.creator.bank,
      PriceINR: order.price.inr,
      PriceUSD: order.price.usd,
      RoyaltyFeePercentage: order.royaltyFee,
      CreatedAt: order.createdAt,
    }));
    setCsvData(csvData);
  }, [orders]);

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      const payload = {
        orderId: modalData.orderId,
        status: "completed",
        ...(modalData.type === "Royalty Fee"
          ? { creatorTransId: formData.transactionId }
          : { sellerTransId: formData.transactionId }),
      };

      const response = await Axios.post(
        "/orders-management/update-transaction",
        payload
      );

      if (response && response.data) {
        // alert("Transaction updated successfully");
        setOpenModal(false);
      } else {
        // alert("Failed to update transaction");
      }
    } catch (error) {
      console.error("Error submitting transaction:", error);
      //   alert("An error occurred while updating the transaction");
    } finally {
      setSubmitting(false);
    }
  };

  const renderModalContent = () => (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
      }}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>
        {modalData.type}
      </Typography>
      <TextField
        fullWidth
        label="Transaction ID"
        value={formData.transactionId || ""}
        onChange={(e) =>
          setFormData({ ...formData, transactionId: e.target.value })
        }
        sx={{ mb: 2 }}
      />
      <Button
        variant="contained"
        fullWidth
        disabled={!formData.transactionId || submitting}
        onClick={handleSubmit}
      >
        {submitting ? <CircularProgress size={24} /> : "Submit"}
      </Button>
      <Button
        variant="outlined"
        fullWidth
        sx={{ mt: 1 }}
        onClick={() => {
          setOpenModal(false); // Close the modal
          setFormData({}); // Clear the input fields
          setModalData({}); // Optional: Reset modal-specific data
        }}
      >
        Cancel
      </Button>
    </Box>
  );

  const columns = [
    {
      field: "buyerName",
      headerName: "Buyer Name",
      width: 160,
      valueGetter: (params) => params.row.buyer.name,
    },
    {
      field: "buyerEmail",
      headerName: "Buyer Email",
      width: 200,
      valueGetter: (params) => params.row.buyer.email,
    },
    {
      field: "sellerName",
      headerName: "Seller Name",
      width: 160,
      valueGetter: (params) => params.row.seller.name,
    },
    {
      field: "sellerEmail",
      headerName: "Seller Email",
      width: 200,
      valueGetter: (params) => params.row.seller.email,
    },
    // {
    //   field: "creatorPayment",
    //   headerName: "Creator Payment",
    //   width: 250,
    //   renderCell: (params) => {
    //     const { amountINR, amountUSD, status, transactionId } =
    //       params.row.creatorPayment || {};

    //     // Tooltip content
    //     const tooltipContent = `
    //       INR: ${amountINR !== undefined ? amountINR : "N/A"}
    //       USD: ${amountUSD !== undefined ? amountUSD : "N/A"}
    //       Status: ${status || "N/A"}
    //       Transaction ID: ${transactionId || "N/A"}
    //     `;

    //     return (
    //       <Tooltip title={<pre>{tooltipContent}</pre>} arrow>
    //         <Box>
    //           <Typography variant="body2" sx={{ mb: 1 }}>
    //             <strong>INR:</strong>{" "}
    //             {amountINR !== undefined ? amountINR : "N/A"}
    //           </Typography>
    //           <Typography variant="body2" sx={{ mb: 1 }}>
    //             <strong>USD:</strong>{" "}
    //             {amountUSD !== undefined ? amountUSD : "N/A"}
    //           </Typography>
    //           <Typography variant="body2">
    //             <strong>Status:</strong> {status || "N/A"}
    //           </Typography>
    //         </Box>
    //       </Tooltip>
    //     );
    //   },
    // },
    {
      field: "creatorPayment",
      headerName: "Creator Payment (INR)",
      width: 250,
      renderCell: (params) => {
        const { amountINR, amountUSD, status, transactionId } =
          params.row.creatorPayment || {};

        // Tooltip content
        const tooltipContent = `
          USD: ${amountUSD !== undefined ? amountUSD : "N/A"}
          Status: ${status || "N/A"}
          Transaction ID: ${transactionId || "N/A"}
        `;

        return (
          <Tooltip title={<pre>{tooltipContent}</pre>} arrow>
            <Typography variant="body2">
              {amountINR !== undefined ? amountINR : "N/A"}
            </Typography>
          </Tooltip>
        );
      },
    },

    {
      field: "sellerBankDetails",
      headerName: "Seller Bank Details",
      width: 300,
      renderCell: (params) => {
        const {
          accountName,
          accountNumber,
          accountType,
          branchName,
          ifscCode,
        } = params.row.sellerBankDetails || {};

        const bankDetails = `
          Account Name: ${accountName || "N/A"}
          Account Number: ${accountNumber || "N/A"}
          Account Type: ${accountType || "N/A"}
          Branch Name: ${branchName || "N/A"}
          IFSC Code: ${ifscCode || "N/A"}
        `;

        return (
          <Tooltip title={<pre>{bankDetails}</pre>} arrow>
            <Typography variant="body2">
              {accountName || "N/A"} - {accountType || "N/A"}
            </Typography>
          </Tooltip>
        );
      },
    },

    {
      field: "SellerRole",
      headerName: "Seller Role",
      width: 200,
      valueGetter: (params) => params.row.seller.role,
    },
    {
      field: "priceINR",
      headerName: "Price (INR)",
      width: 160,
      valueGetter: (params) => params.row.price.inr,
    },
    {
      field: "priceUSD",
      headerName: "Price (USD)",
      width: 160,
      valueGetter: (params) => params.row.price.usd,
    },
    {
      field: "royaltyFee",
      headerName: "Royalty Fee (%)",
      width: 160,
      valueGetter: (params) => params.row.royaltyFee,
    },
    {
      field: "creatorName",
      headerName: "Royaltiy Name",
      width: 200,
      renderCell: (params) => {
        const creator = params?.row?.creator;

        if (creator?.id !== "N/A") {
          return (
            <>
              <Typography variant="body2">{creator?.name || "N/A"}</Typography>
            </>
          );
        }

        return <Typography variant="body2">N/A</Typography>;
      },
    },

    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          {params.row.creator.id !== "N/A" ? (
            <Button
              variant="contained"
              onClick={() => {
                console.log("log", params.row.creator);
                setModalData({
                  type: "Royalty Fee",
                  orderId: params.row.orderId,
                });
                setOpenModal(true);
              }}
            >
              Royalty Fee
            </Button>
          ) : (
            <></>
          )}

          <Button
            variant="contained"
            onClick={() => {
              setModalData({
                type: "Seller Fee",
                orderId: params.row.orderId,
              });
              setOpenModal(true);
            }}
          >
            Seller Fee
          </Button>
        </Stack>
      ),
    },
  ];

  return (
    <>
      <SideBar />
      <Body>
        <Appbar />
        <Paper elevation={0} sx={{ p: 2, borderRadius: 2, my: 2 }}>
          <Stack direction="row" alignItems="center">
            <Stack direction="row" alignItems="center" sx={{ flex: 1 }}>
              <Typography variant="p">Orders</Typography>
            </Stack>
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={searchQuery}
              onChange={handleSearch}
              sx={{ flexGrow: 1 }}
            />
            <CSVLink
              data={csvData}
              filename={`orders-${new Date().toISOString()}.csv`}
            >
              <Button variant="outlined">
                <CloudDownloadIcon sx={{ mr: 1 }} />
                Download Data
              </Button>
            </CSVLink>
          </Stack>
        </Paper>
        <Paper
          sx={{ height: "80%", width: "100%", overflowX: "auto" }}
          elevation={0}
        >
          <DataGrid
            getRowId={(row) => row.orderId}
            rows={filteredOrders}
            columns={columns}
            pageSizeOptions={[25]}
            disableRowSelectionOnClick
            loading={loading}
            disableColumnMenu
          />
        </Paper>
        <Modal open={openModal} onClose={() => setOpenModal(false)}>
          {renderModalContent()}
        </Modal>
      </Body>
    </>
  );
}

export default Table;
