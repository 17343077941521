// import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
// import {
//   Box,
//   Button,
//   CircularProgress,
//   IconButton,
//   Paper,
//   Stack,
//   Typography,
// } from "@mui/material";
// import { DataGrid, useGridSlotComponentProps } from "@mui/x-data-grid";
// import EditIcon from "@mui/icons-material/Edit";
// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { handleDate } from "../../helpers/date.helper";
// import { getAllSlbsAPI } from "../../redux/slb/getAllSlbs";
// import Appbar from "../../ui/Appbar";
// import Body from "../../ui/Body";
// import SideBar from "../../ui/SideBar";
// import AsignSLB from "./AsignSLB";
// import RevertSLBsFromSLB from "./RevertSLBsFromSLB";
// import AssignSLBModel from "./AssignSLBModel";
// import { getCurrentAdminAPI } from "../../redux/users/getCurrentAdmin";
// import { getSlbsForOrgAPI } from "../../redux/slb/getSlbsForOrg";
// import { revokeSlbFromUserAPI } from "../../redux/slb/revokeSlbFromUser";

// function AssignSLBsTable() {
//   const getAllSlbsSelector = useSelector((state) => state?.getAllSlbs);
//   const getSlbsForOrgSelector = useSelector((state) => state?.getSlbsForOrg);
//   const [isLoading, setIsLoading] = useState(true);
//   const getCurrentAdminSelector = useSelector(
//     (state) => state?.getCurrentAdmin
//   );

//   const dispatch = useDispatch();

//   const [dataResult, setDataResult] = useState([]);

//   const adminUser = getCurrentAdminSelector.result;

//   //   TEMP
//   const [assignSlb, setAssignSlb] = useState(false);
//   const [slbIds, setSlbIds] = useState();

//   // console.log(slbIds[slbIds.length - 1]);
//   console.log(">>", slbIds);

//   useEffect(() => {
//     dispatch(getCurrentAdminAPI());
//     if (adminUser.role === "SuperAdmin") dispatch(getAllSlbsAPI());
//     else if (adminUser.type === "Organization") dispatch(getSlbsForOrgAPI());
//   }, []);

//   useEffect(()=>{
//     if (adminUser.role === "SuperAdmin") {
//       if(getAllSlbsSelector.loading === false){
//         setDataResult(getAllSlbsSelector.result)
//         setIsLoading(false);
//       }
//       // setIsLoading(getAllSlbsSelector.loading);
//     } else if (adminUser.type === "Organization") {
//       if(getSlbsForOrgSelector.loading === false){
//         setDataResult(getSlbsForOrgSelector.result);
//         setIsLoading(false);
//       }
//     }
//   }, [getAllSlbsSelector, getSlbsForOrgSelector])

//   let columns = [
//     {
//       field: "title",
//       headerName: "Name",
//       width: 160,
//       headerClassName: "super-app-theme--header",
//       sortable: false,
//     },
//     {
//       field: "slbId",
//       headerName: "slbId",
//       width: 160,
//       headerClassName: "super-app-theme--header",
//       sortable: false,
//       renderCell: (params) => {
//         return <Typography variant="p">{params?.row?.slbId}</Typography>;
//       },
//     },
//     {
//       field: "updated_at",
//       headerName: "Date",
//       width: 160,
//       headerClassName: "super-app-theme--header",
//       sortable: false,
//       renderCell: (params) => {
//         return (
//           <Typography variant="p">
//             {handleDate(params?.row?.updated_at)}
//           </Typography>
//         );
//       },
//     },
//     {
//       field: "Buyer",
//       headerName: "Buyer",
//       width: 160,
//       headerClassName: "super-app-theme--header",
//       sortable: false,
//       renderCell: (params) => {
//         return <Typography variant="p">{params?.row?.ownerName}</Typography>;
//       },
//     },
//     {
//       field: "country",
//       headerName: "Country",
//       width: 160,
//       headerClassName: "super-app-theme--header",
//       sortable: false,
//     },
//     {
//       field: "cost?.inr",
//       headerName: "Sale Price (INR)",
//       width: 160,
//       headerClassName: "super-app-theme--header",
//       sortable: false,
//       renderCell: (params) => {
//         return <Typography variant="p">{params?.row?.cost?.inr}</Typography>;
//       },
//     },

//     // TEMP: To be removed later
//     {
//       field: "assignSlbs",
//       headerName: "Action",
//       width: 160,
//       headerClassName: "super-app-theme--header",
//       sortable: false,
//       renderCell: (params) => {
//           const isAssignedTo = params?.row?.assignedTo?.name;
//           console.log(`bbsingh -------->>>>>>>>>> ${isAssignedTo}`);

//           return (isAssignedTo === undefined || isAssignedTo === null) ? (
//             <Button
//               sx={{ my: 1 }}
//               onClick={() => handleAssignSlb(params?.row?.slbId)}
//               color="info"
//               // endIcon={<EditIcon />}
//             >
//               Assign
//             </Button>
//           ) : (
//             <Button
//               sx={{ my: 1 }}
//               onClick={async () => {
//                 await dispatch(
//                   revokeSlbFromUserAPI({
//                     slbId: params?.row?.slbId,
//                     name: params?.row?.assignedTo?.name,
//                     email: params?.row?.assignedTo?.email,
//                   })
//                 );

//                 if (adminUser.role === "SuperAdmin")
//                   await dispatch(getAllSlbsAPI());
//                 else await dispatch(getSlbsForOrgAPI());
//               }}
//               color="error"
//               // endIcon={<EditIcon />}
//             >
//               Revoke
//             </Button>
//           );
//         }
//     },
//     // {
//     //   field: "status",
//     //   headerName: "Status",
//     //   width: 160,
//     //   headerClassName: 'super-app-theme--header',
//     //   sortable: false,
//     //   renderCell: (params) => {
//     //     return (
//     //       <>
//     //         {params?.row?.asigned
//     //           ? <Typography variant="p" color='primary' >Asigned</Typography>
//     //           : 'Rejected' && <Typography variant="p" color='error'>Not Asigned Yet</Typography>}
//     //       </>
//     //     )

//     //   }
//     // },
//   ];

//   const handleAssignSlb = (permId) => {
//     setSlbIds(permId);
//     setAssignSlb(true);
//   };

//   return (
//     <>
//       <SideBar />
//       <Body>
//         <Appbar />
//         <Paper elevation={0} sx={{ p: 2, borderRadius: 2, my: 2 }}>
//           <Stack
//             direction="row"
//             alignItems="center"
//             justifyContent="space-between"
//           >
//             <Stack direction="row" alignItems="center">

//               <Typography variant="p">Assign SLBs</Typography>
//             </Stack>
//             {/* <Box sx={{ display: "flex" }}>
//               <Stack direction="row" spacing={2} alignItems="center">
//                 <Button variant="outlined" onClick={() => setRevert(true)}>
//                   Revert Back
//                 </Button>
//                 <Button
//                   variant="outlined"
//                   disabled={!asignData?.length}
//                   onClick={() => setAsign(true)}
//                 >
//                   Asign
//                 </Button>
//               </Stack>
//             </Box> */}
//           </Stack>
//         </Paper>

//         <Paper sx={{ height: '80%', width: "100%" }} elevation={0}>
//           {
//             isLoading ?
//             <CircularProgress /> :
//             <DataGrid
//               getRowId={(row) => row?._id}
//               getRowHeight={() => "auto"}
//               rows={dataResult}
//               columns={columns}
//               pageSizeOptions={[25]}
//               loading={isLoading && <CircularProgress />}
//               disableColumnMenu
//               disableColumnFilter
//               disableColumnSelector
//               disableRowSelectionOnClick
//             />
//           }
//         </Paper>
//       </Body>

//       {assignSlb && (
//         <AssignSLBModel
//           open={assignSlb}
//           setOpen={setAssignSlb}
//           //   asignData={asignData}
//           slbId={slbIds}
//         />
//       )}
//       {/* <AsignSLB open={asign} setOpen={setAsign} asignData={asignData} /> */}
//       {/* <RevertSLBsFromSLB open={revert} setOpen={setRevert} slbId={slbIds} /> */}
//     </>
//   );
// }

// export default AssignSLBsTable;



import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  TextField, // Added TextField for search input
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleDate } from "../../helpers/date.helper";
import { getAllSlbsAPI } from "../../redux/slb/getAllSlbs";
import Appbar from "../../ui/Appbar";
import Body from "../../ui/Body";
import SideBar from "../../ui/SideBar";
import AssignSLBModel from "./AssignSLBModel";
import { getCurrentAdminAPI } from "../../redux/users/getCurrentAdmin";
import { getSlbsForOrgAPI } from "../../redux/slb/getSlbsForOrg";
import { revokeSlbFromUserAPI } from "../../redux/slb/revokeSlbFromUser";

function AssignSLBsTable() {
  const getAllSlbsSelector = useSelector((state) => state?.getAllSlbs);
  const getSlbsForOrgSelector = useSelector((state) => state?.getSlbsForOrg);
  const [isLoading, setIsLoading] = useState(true);
  const getCurrentAdminSelector = useSelector(
    (state) => state?.getCurrentAdmin
  );

  const dispatch = useDispatch();

  const [dataResult, setDataResult] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // Added filteredData state for search functionality

  const [searchQuery, setSearchQuery] = useState(""); // Added searchQuery state

  const adminUser = getCurrentAdminSelector.result;

  const [assignSlb, setAssignSlb] = useState(false);
  const [slbIds, setSlbIds] = useState();

  useEffect(() => {
    dispatch(getCurrentAdminAPI());
    if (adminUser.role === "SuperAdmin") dispatch(getAllSlbsAPI());
    else if (adminUser.type === "Organization") dispatch(getSlbsForOrgAPI());
  }, []);

  useEffect(() => {
    if (adminUser.role === "SuperAdmin") {
      if (getAllSlbsSelector.loading === false) {
        setDataResult(getAllSlbsSelector.result);
        setFilteredData(getAllSlbsSelector.result); // Sync filteredData with initial data
        setIsLoading(false);
      }
    } else if (adminUser.type === "Organization") {
      if (getSlbsForOrgSelector.loading === false) {
        setDataResult(getSlbsForOrgSelector.result);
        setFilteredData(getSlbsForOrgSelector.result); // Sync filteredData with initial data
        setIsLoading(false);
      }
    }
  }, [getAllSlbsSelector, getSlbsForOrgSelector]);

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = dataResult.filter(
      (row) =>
        row.title?.toLowerCase().includes(query) ||
        row.slbId?.toLowerCase().includes(query) ||
        row.ownerName?.toLowerCase().includes(query) ||
        row.country?.toLowerCase().includes(query)
    );
    setFilteredData(filtered);
  };

  const handleAssignSlb = (permId) => {
    setSlbIds(permId);
    setAssignSlb(true);
  };

  let columns = [
    {
      field: "title",
      headerName: "Name",
      width: 160,
      headerClassName: "super-app-theme--header",
      sortable: false,
    },
    {
      field: "slbId",
      headerName: "slbId",
      width: 160,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        return <Typography variant="p">{params?.row?.slbId}</Typography>;
      },
    },
    {
      field: "updated_at",
      headerName: "Date",
      width: 160,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography variant="p">
            {handleDate(params?.row?.updated_at)}
          </Typography>
        );
      },
    },
    {
      field: "Buyer",
      headerName: "Buyer",
      width: 160,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        return <Typography variant="p">{params?.row?.ownerName}</Typography>;
      },
    },
    {
      field: "country",
      headerName: "Country",
      width: 160,
      headerClassName: "super-app-theme--header",
      sortable: false,
    },
    {
      field: "cost?.inr",
      headerName: "Sale Price (INR)",
      width: 160,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        return <Typography variant="p">{params?.row?.cost?.inr}</Typography>;
      },
    },
    {
      field: "assignSlbs",
      headerName: "Action",
      width: 160,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        const isAssignedTo = params?.row?.assignedTo?.name;

        return isAssignedTo === undefined || isAssignedTo === null ? (
          <Button
            sx={{ my: 1 }}
            onClick={() => handleAssignSlb(params?.row?.slbId)}
            color="info"
          >
            Assign
          </Button>
        ) : (
          <Button
            sx={{ my: 1 }}
            onClick={async () => {
              await dispatch(
                revokeSlbFromUserAPI({
                  slbId: params?.row?.slbId,
                  name: params?.row?.assignedTo?.name,
                  email: params?.row?.assignedTo?.email,
                })
              );

              if (adminUser.role === "SuperAdmin")
                await dispatch(getAllSlbsAPI());
              else await dispatch(getSlbsForOrgAPI());
            }}
            color="error"
          >
            Revoke
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <SideBar />
      <Body>
        <Appbar />
        <Paper elevation={0} sx={{ p: 2, borderRadius: 2, my: 2 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="p">Assign SLBs</Typography>
          </Stack>
        </Paper>

        <Box sx={{ my: 2 }}>
          {/* Search Input */}
          <TextField
            label="Search"
            variant="outlined"
            fullWidth
            value={searchQuery}
            onChange={handleSearch}
          />
        </Box>

        <Paper sx={{ height: "80%", width: "100%" }} elevation={0}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <DataGrid
              getRowId={(row) => row?._id}
              getRowHeight={() => "auto"}
              rows={filteredData} // Use filteredData for the DataGrid rows
              columns={columns}
              pageSizeOptions={[25]}
              loading={isLoading && <CircularProgress />}
              disableColumnMenu
              disableColumnFilter
              disableColumnSelector
              disableRowSelectionOnClick
            />
          )}
        </Paper>
      </Body>

      {assignSlb && (
        <AssignSLBModel
          open={assignSlb}
          setOpen={setAssignSlb}
          slbId={slbIds}
        />
      )}
    </>
  );
}

export default AssignSLBsTable;
