import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ClearIcon from '@mui/icons-material/Clear';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Avatar, Button, Card, IconButton, Paper, Stack, Typography } from "@mui/material";
import { default as React, useState } from "react";
import { useCSVReader } from 'react-papaparse';
import { useDispatch, useSelector } from "react-redux";
import { uploadMultipleCLbsAPI } from "../../redux/clb/uploadMultipleCLbs";
import Appbar from "../../ui/Appbar";
import Body from "../../ui/Body";
import SideBar from "../../ui/SideBar";
import { useNavigate } from "react-router-dom";
import { uploadTatumFileAPI } from "../../redux/clb/uploadMultipleCLbs";
import toast from 'react-hot-toast';


const BulkClbUploader = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const uploadMultipleCLbsSelector = useSelector(state => state?.uploadMultipleCLbs)
  const { message, error } = uploadMultipleCLbsSelector
  const { CSVReader } = useCSVReader();


  const [clbs, setClbs] = useState()
  const [snack, setSnack] = useState(false)



  // const handleClbsUpload = async () => {
  //   try {
  //     const response = await dispatch(uploadMultipleCLbsAPI(clbs));
      
  //     if (uploadMultipleCLbsAPI.fulfilled.match(response)) {
  //       console.log("Upload successful:", response.payload);
  //       navigate('/clbs'); // Redirect to /clbs when the upload is successful
  //     } else {
  //       console.error("Upload failed:", response.payload);
  //     }
  //   } catch (error) {
  //     console.error("An error occurred during the upload:", error);
  //   }
  // };


  const handleClbsUpload = async () => {
    try {
      if (!clbs || !Array.isArray(clbs.data)) {
        console.error("Invalid or empty clbs data:", clbs);
        return;
      }
  console.log("clbs.data===",clbs.data)
      for (const nft of clbs.data) {
        const { files } = nft; // Extract file URL
  
        if (!files) {
          console.warn("Missing file URL for NFT:", nft);
          continue; // Skip rows with missing files
        }
  
        try {
          // Fetch IPFS file from Tatum for the current row
          const tatumResponse = await dispatch(
            uploadTatumFileAPI({ fileUrl: files, ipfs: true })
          );
  
          if (uploadTatumFileAPI.fulfilled.match(tatumResponse)) {
            // Append IPFS file to the current row
            nft.ipfsFile = tatumResponse.payload.ipfsFile;
            console.log(`IPFS file appended for ${files}:`, nft);
          } else {
            console.warn("Failed to fetch IPFS file for:", files);
          }
        } catch (error) {
          console.error("Error fetching IPFS file for fileUrl:", files, error);
        }
      }
  
      // Check if at least one row has been updated
      if (!clbs.data.some((nft) => nft.ipfsFile)) {
        console.warn("No rows have a valid IPFS file, upload aborted.");
        toast.error("No rows have a valid IPFS file, upload aborted.");
        return;
      }
  
      // Dispatch updated clbs.data to the upload API
      const response = await dispatch(uploadMultipleCLbsAPI(clbs));
  
      if (uploadMultipleCLbsAPI.fulfilled.match(response)) {
        console.log("All rows successfully uploaded:", response.payload);
        navigate('/clbs');
      } else {
        console.error("Failed to upload rows:", response.payload);
      }
    } catch (error) {
      console.error("Error in handling CLBS upload process:", error);
    }
  };
  
  
  

  return (

    <>
      <SideBar />
      <Body>
        <Appbar />
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          <Stack direction='row' alignItems='center'>
            <IconButton onClick={()=>navigate('/clbs')}><ArrowBackIosIcon /></IconButton>
            <Typography>CLBs</Typography>
          </Stack>
        </Paper>

        <Paper sx={{ p: 4, my: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Stack alignItems='center' spacing={2}>
            <CSVReader
            onUploadAccepted={(results) => {
              console.log("Raw CSV Results:", results);
            
              const filteredData = results.data.filter(row =>
                Array.isArray(row) && row.some(cell => cell && cell.trim() !== "")
              );
            
              console.log("Filtered CSV Data:", filteredData);
            
              // Map headers to values
              const [headers, ...rows] = filteredData;
              const mappedData = rows.map((row) => {
                const rowData = {};
                headers.forEach((header, index) => {
                  rowData[header] = row[index]; // Map each header to its corresponding value
                });
                return rowData;
              });
            
              console.log("Mapped Data:", mappedData);
            
              setClbs({
                ...results,
                data: mappedData,
              });
            }}
            
            
            >{({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps, }) => (
              <>
                <Avatar sx={{ width: 100, height: 100 }}  {...getRootProps()}><CloudUploadIcon fontSize="large" /></Avatar>
                {acceptedFile && <IconButton sx={{ bgcolor: "red", color: '#fff' }} {...getRemoveFileProps()} ><ClearIcon /></IconButton>}
                <ProgressBar />
              </>
            )}
            </CSVReader>
            <Typography>CSV file Only</Typography>
            <Typography>Max size: 100 MB</Typography>
          </Stack>
          <Button disabled={!clbs?.data?.length} onClick={handleClbsUpload} fullWidth sx={{ my: 2 }} variant="contained">Upload</Button>
        </Paper>
      </Body >
    </>
  );
};

export default BulkClbUploader;
